import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby"
import Img from "gatsby-image"
import "../components/highlightcard.css";

export default function Projects({ data })  {
    const projects = data.projects.nodes;

    return (
        <Layout>
            <SEO title="Projects" />

            <h2 className="text-center" style={{marginBottom: 20}}>Projects</h2>

            <div className="container px-2 px-md-5 mx-md-5">
                {projects.map((project, index) => (
                  <div className="row highlight-card rounded border-0 g-0 g-md-4 my-md-4" id={project.frontmatter.title}
                       key={project.frontmatter.title + "_" + index}>
                    <div className="col-12 col-md-4 d-md-block d-none">
                      {project.frontmatter.thumb ?
                        <Img fluid={project.frontmatter.thumb.childImageSharp.fluid}
                             style={{ width: 350 }} className="mx-auto rounded shadow-sm float-end"/>
                        : ""
                      }
                    </div>
                    <div className="col-12 col-md-8 ">
                      <div className="text-center text-md-start fs-3 lead text-black">{project.frontmatter.title}</div>
                      <div className="d-flex justify-content-center justify-content-md-start">
                        {project.frontmatter.tags.split(",").map((tag, index2) => (
                          <div className="font-monospace mx-3 mx-md-0 me-md-5 text-center text-md-start"
                               key={tag + "_" + index2}>{tag}</div>
                        ))}
                      </div>
                      <div className="d-md-none d-block my-1 text-center">
                        {project.frontmatter.thumb ?
                          <Img fluid={project.frontmatter.thumb.childImageSharp.fluid}
                               className="mx-2 p-2 rounded"/>
                          : ""
                        }
                      </div>
                      <div className="mt-2 w-100 w-md-75"
                           style={{ textAlign: "justify" }}>{project.frontmatter.abstract}</div>
                    </div>
                    <div className="d-block d-md-none my-5">
                      <hr/>
                    </div>
                  </div>
                ))}
            </div>
        </Layout>
    );
}

export const query = graphql`
query ProjectPage {
  projects: allMarkdownRemark(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {fileAbsolutePath: {regex: "/(src/projects)/"}}
    ) {
    nodes {
      frontmatter {
        title
        tags
        abstract
        thumb {
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
            fixed {
                ...GatsbyImageSharpFixed
            }
          }
        }
      }
      fileAbsolutePath
    }
  }

}
`
